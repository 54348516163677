import React from 'react'
import './DemoForm.css'
import { FaPaw, FaEnvelope, FaPhone } from 'react-icons/fa'

function DemoForm({
  handleCloseModal,
  pupSelection,
  form,
  setForm,
  handleSubmitForm,
}) {
  const { title, img, price, deposit, sex, goHomeDate } = pupSelection
  // logic for an eventual API call to twilio to send a text message

  // const handleChange = (event) => {
  //   let value = event.target.value
  //   value = value.replace(/\D/g, '')
  //   value = value.substring(0, 10)

  //   const formattedValue = value.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')

  //   setForm(formattedValue)
  // }

  const handleChange = (e) => {
    let value = e.target.value
    if (e.target.name === 'phone') {
      // if (value.match(/^\d{3}-\d{3}-\d{4}$/)) {
      //   setForm({ ...form, [e.target.name]: value })
      //   return
      // }

      value = value.replace(/\D/g, '')
      value = value.substring(0, 10)

      const formattedValue = value.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')

      setForm({ ...form, [e.target.name]: formattedValue })
    }
    if (e.target.name !== 'phone') {
      const value = e.target.value
      setForm({ ...form, [e.target.name]: value })
    }
  }

  return (
    <div className="modal-shade">
      <div className="modal">
        <form className="form">
          <div className="exit-btn-container">
            <div className="exit-btn" onClick={handleCloseModal}>
              <FaPaw className="form-paw-icon" />
            </div>
          </div>

          <h3>
            <mark>You're interested in little {title}!</mark>
          </h3>
          <div className="dog">
            <img src={img} alt="" />
          </div>
          <p>
            {sex === 'male' ? 'He' : 'She'} is very excited to meet you. You can
            reach out to the breeder, Karistia Lipowicz, to arrange a visit with
            the puppies or have any questions answered. {title} will be ready to
            go home on {goHomeDate}{' '}
          </p>

          <h3>Call Karistia to inquire about {title}:</h3>
          <div className="button-81">
            <FaPhone />
            <a
              style={{ marginLeft: '0.5rem' }}
              class="phone-number"
              href="tel:+19895223646"
            >
              Call us at <span>989-522-3646</span>
            </a>
          </div>

          <h3 style={{ marginTop: '2rem' }}>
            <span style={{ textTransform: 'lowercase' }}>
              frankenmuthminiaussies@gmail.com
            </span>
          </h3>
          <div className="button-81">
            <FaEnvelope />
            <a
              style={{ marginLeft: '0.5rem' }}
              class="phone-number"
              href="mailto:your-email@example.com"
            >
              Click here to email us
            </a>
          </div>

          {/* <div className="label">
            <mark>Name:</mark>
            <input
              type="text"
              name="name"
              value={form.name}
              onChange={handleChange}
            />
          </div>
          <div className="label">
            <mark>Email:</mark>
            <input
              type="email"
              name="email"
              value={form.email}
              onChange={handleChange}
            />
          </div>
          <div className="label">
            <mark>Phone:</mark>
            <input
              type="tel"
              name="phone"
              value={form.phone}
              onChange={handleChange}
              pattern="\d{3}-\d{3}-\d{4}"
              placeholder="1234567890"
            />
          </div>
          <div className="label">
            <label>
              <mark>Message:</mark>
            </label>
            <textarea
              name="message"
              value={form.message}
              onChange={handleChange}
            />
          </div> */}
          <button
            type="submit"
            style={{ marginTop: '6rem' }}
            className="button-81"
            onClick={() => handleCloseModal()}
          >
            go back
          </button>
        </form>
      </div>
    </div>
  )
}
export default DemoForm
