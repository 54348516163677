import React from 'react'

const Menu = ({ items, handleOpenModal, handleCloseModal }) => {
  return (
    <div className="section-center">
      {items.map((menuItem) => {
        const originalItem = { ...menuItem }
        const { id, title, img, desc, price, sold } = menuItem
        return (
          <article key={id} className="menu-item">
            <img src={img} alt={title} className="photo" />
            <div className="item-info">
              <header>
                <h4>{title}</h4>
                <h4 className="price">${price}</h4>
              </header>
              <p className="item-text">{desc}</p>
              {sold ? (
                <b>Sorry! This puppy has already found a home</b>
              ) : (
                <button
                  className="btn-contact"
                  onClick={() => handleOpenModal(originalItem)}
                >
                  I'm interested in {title}!
                </button>
              )}
            </div>
          </article>
        )
      })}
    </div>
  )
}

export default Menu
