import React, { useState, useEffect } from 'react'
import { ReactComponent as Logo } from './fma_logo_puppy.svg'
import { ReactComponent as Logo2 } from './fma_logo_watercolor_puppy.svg'
import {
  FaPaw,
  FaPhone,
  FaEnvelope,
  FaFacebook,
  FaGoogle,
} from 'react-icons/fa'
import Menu from './Menu'
import Categories from './Categories'
import items from './data'
import DemoForm from './DemoForm'
import ResponsiveAppBar from './ResponsiveAppBar'
// import { sendSMS } from './send_sms'
const allCategories = ['all', ...new Set(items.map((item) => item.category))]

function App() {
  const [menuItems, setMenuItems] = useState(items)
  const [categories, setCategories] = useState(allCategories)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [pupSelection, setPupSelection] = useState({})
  const [response, setResponse] = useState(null)
  const [isSendSMS, setSendSMS] = useState(true)
  const [form, setForm] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
    puppy: '',
  })

  // useEffect(() => {
  //   if (isSendSMS) {
  //     fetch('http://localhost:3000/sendSMS')
  //       .then((res) => res.json())
  //       .then((data) => {
  //         setResponse(data)
  //         console.log(data)
  //       })
  //       .catch((error) => console.error(error))
  //   }
  //   console.log('api call')
  // }, [])

  const filterItems = (category) => {
    if (category === 'all') {
      setMenuItems(items)
      return
    }
    const newItems = items.filter((item) => item.category === category)
    setMenuItems(newItems)
  }

  // MODAL LOGIC

  const handleOpenModal = (selectedPuppy) => {
    setPupSelection(selectedPuppy)
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const handleSubmitForm = (puppy, event) => {
    event.preventDefault()
    const message = `Name: ${form.name} Phone: ${form.phone} Email: ${form.email} Puppy: ${puppy} Message: ${form.message}`
    console.log('handleSubmitForm has run')
    // sendSMS(message)
    // setIsModalOpen(false)
  }

  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = 'hidden'
    }
    return () => {
      if (isModalOpen) {
        document.body.style.overflow = 'auto'
      }
    }
  }, [isModalOpen])

  return (
    <>
      <ResponsiveAppBar />
      <main>
        <section className="menu section">
          <h2 className="pageTitle">
            Frankenmuth Mini Aussies
            <FaPaw style={{ marginLeft: '1rem' }} />
          </h2>
          <div className="pageTitle">
            <div
              className="button-81"
              style={{ marginRight: '2rem', marginBottom: '1rem' }}
            >
              <FaPhone />
              <a
                style={{ marginLeft: '0.5rem' }}
                class="phone-number"
                href="tel:+19895223646"
              >
                Call us at <span>989-522-3646</span>
              </a>
            </div>

            <div className="button-81">
              <FaEnvelope />
              <a
                style={{ marginLeft: '0.5rem' }}
                class="phone-number"
                href="mailto:your-email@example.com"
              >
                Click here to email us
              </a>
            </div>
          </div>

          <div className="title">
            <Logo className="pupLogo" />
            <h2 style={{ marginTop: '1rem' }}>Our Puppies</h2>
            <div className="underline"></div>
          </div>
          <Categories categories={categories} filterItems={filterItems} />
          <p
            style={{
              marginBottom: '4rem',
              textAlign: 'center',
              fontSize: '2rem',
              fontStyle: 'italic',
              marginTop: '0rem',
            }}
          >
            <i>
              We have sold all puppies from our May 2024 litter! Please contact
              us to reserve a pup for future litters.
            </i>
          </p>
          <Menu
            items={menuItems}
            handleOpenModal={handleOpenModal}
            handleCloseModal={handleCloseModal}
          />
        </section>

        <div className="title">
          <h2 style={{ marginTop: '1rem' }}>About Us</h2>
          <div className="underline"></div>
          <br></br>
          <h3>About Frankenmuth Mini Aussies</h3>
          <img
            src="./images/backyard.jpg"
            alt="Louie the Mini Aussie"
            className="photo2"
          />
          <p className="text">
            Founded in 2021, Frankenmuth Mini Aussies has its roots in
            Frankenmuth, Michigan, fondly known as 'Little Bavaria,' but has now
            found its home in Clearwater, Florida. Karistia's enduring love for
            dogs, cultivated since her early years, inspired the establishment
            of this breeding venture, focusing on her beloved breed, the
            Miniature Australian Shepherd (also recognized as the 'American
            Shepherd').
          </p>
          <p className="text">
            Karistia has been passionate about dogs for some time. Since she was
            a little girl she always had dogs in her life as they were regarded
            as an important part of the family.
          </p>

          <p className="text">
            The journey commenced in October 2021 when Lacie, our dame, gave
            birth to 7 adorable, joyful, and robust puppies. Since our humble
            beginnings in Frankenmuth, we have relocated to the sunny city of
            Clearwater, Florida, abandoning the rural setting for the vibrant
            coastal life. Despite the change in surroundings, our commitment to
            providing a nurturing and loving environment for our puppies remains
            unwavering. Frankenmuth Mini Aussies ensures a secure space for
            these puppies to thrive, surrounded by ample room for play and
            exploration.{' '}
          </p>
          <p className="text">
            When contemplating the addition of one of our puppies to your
            family, rest assured that they have been meticulously cared for,
            showered with love and attention. Whether in Michigan or now in
            Clearwater, our dedication to the well-being of our Mini Aussies
            persists, ensuring that they are not only happy and healthy during
            their time with us but also ready to bring joy to your home.
          </p>
          <h3>Our Dame Lacie</h3>
          <img
            src="./images/Lacie.jpg"
            alt="Lacie the Mini Aussie"
            className="photo2"
          />
          <p className="text">
            Lacie is a huge sweetheart and loves to cuddle with her owners. She
            will often jump into your lap or arms when you least expect it. If
            she's not in your lap, she'll be sure to be close by.
          </p>
          <h3>Our Sire Louie</h3>
          <img
            src="./images/Louie.jpg"
            alt="Louie the Mini Aussie"
            className="photo2"
          />
          <p className="text">
            Louie is a spunky, loving, and protective male who is sure to alert
            his family of strangers nearby or any happenings outside the house.
          </p>
        </div>

        <div className="title">
          <div className="underline"></div>
          <br></br>
          <p className="mediaLinks">
            <FaFacebook />
            &nbsp;
            <a href="https://www.facebook.com/muthminiaussies" target="_blank">
              Facebook
            </a>
          </p>
          <p className="mediaLinks">
            <FaGoogle />
            &nbsp;
            <a href="https://maps.app.goo.gl/rQMEawybjm36gH676" target="_blank">
              Google Business Location
            </a>
          </p>
        </div>

        <div className="form-body">
          {isModalOpen && (
            <DemoForm
              handleCloseModal={handleCloseModal}
              pupSelection={pupSelection}
              form={form}
              setForm={setForm}
              handleSubmitForm={handleSubmitForm}
            />
          )}
        </div>
      </main>
    </>
  )
}

export default App
