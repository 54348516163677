const menu = [
  {
    id: 1,
    sold: true,
    title: 'Gooseberry',
    category: 'red merle',
    price: 2000.0,
    deposit: 550,
    img: './images/Gooseberry_4wks.jpg',
    age: '',
    sex: 'male',
    goHomeDate: 'August 3rd, 2024',
    favToy: '',
    desc: `Gooseberry is a lively, blue-eyed wonder who loves to play with his stuffed bear toy and run around with his litter mates`,
  },
  {
    id: 2,
    sold: true,
    title: 'Fig',
    category: 'black tri',
    price: 1800.0,
    deposit: 550,
    img: './images/Fig_4wks_SOLD.jpg',
    age: '',
    sex: 'female',
    goHomeDate: 'August 3rd, 2024',
    favToy: '',
    desc: `Fig is a little sweetheart who enjoys chewing on her tug rope and cuddling up with her brothers and sisters`,
  },
  {
    id: 3,
    sold: true,
    title: 'Mango',
    category: 'red tri',
    price: 1800.0,
    deposit: 550,
    img: './images/Mango_4wks.JPG',
    age: '',
    sex: 'female',
    goHomeDate: 'August 3rd, 2024',
    favToy: 'Mr. Frog',
    desc: `Mango's blue eyes can pull at your heartstrings and though she can be relaxed and sweet, she can surprise with bouts of excitement in chewing on her favorite Mr. Frog toy`,
  },
  {
    id: 4,
    sold: true,
    title: 'Peach',
    category: 'red merle',
    price: 2000.0,
    deposit: 550,
    img: './images/Peach_4wks_2.JPG',
    age: '',
    sex: 'female',
    goHomeDate: 'August 3rd, 2024',
    favToy: '',
    desc: `Peach absolutely loves playing with her brothers and sisters--especially tug-a-rope! She's a sweetheart who enjoys relaxing and cuddling up after a hard day's play`,
  },
  {
    id: 5,
    sold: true,
    title: 'Dragonfruit',
    category: 'black tri',
    price: 1800.0,
    deposit: 550,
    img: './images/Dragonfruit_4wks.JPG',
    age: '',
    sex: 'male',
    goHomeDate: 'August 3rd, 2024',
    favToy: '',
    desc: `Dragonfruit's big blue eyes are sure to capture your gaze and make cuddling with him irresistable! He's a cutie who loves to play and snuggle beside you whenever he can `,
  },
  {
    id: 6,
    sold: true,
    title: 'Blueberry',
    category: 'blue merle',
    price: 1800.0,
    deposit: 550,
    img: './images/Blueberry_2wks_SOLD.JPG',
    age: '',
    sex: 'female',
    goHomeDate: 'August 3rd, 2024',
    favToy: '',
    desc: `Blueberry is a curious little fellow who enjoys exploring and discovering new nooks and crannies. She's sure to capture your heart with her warm gaze and playful attitude!`,
  },
  {
    id: 7,
    sold: true,
    title: 'Cherry',
    category: 'red tri',
    price: 1600.0,
    deposit: 550,
    img: './images/Cherry_2wks_SOLD.JPG',
    age: '',
    sex: 'female',
    goHomeDate: 'August 3rd, 2024',
    favToy: '',
    desc: `Cherry loves to play and will go to great lengths to reach her favorite chew toys. After a hard day's play she enjoys cuddling up with her litter mates and warming up to her human friends`,
  },
]
export default menu
